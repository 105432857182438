<template>
  <div>
    <!-- Loading -->
    <div class="loading-template" v-if="loading">
      <loading-indicator />
    </div>

    <main-header />

    <!-- Content -->
    <section>
      <div class="container">
        <!-- Breadcrumb -->
        <div class="_flex _center">
          <nav aria-label="breadcrumb" style="height:40px!important">
            <ol class="breadcrumb  bg-transparent tiny">
              <li class="breadcrumb-item"><a href="#">Accueil</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                Mes artites favoris
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="container">
        <div class="px-5-md px-8-lg my-10">
          <div class="text-center">
            <h2><b>Mes artites favoris</b></h2>
            <p>({{ count }} artiste{{ count > 1 ? "s" : "" }})</p>
          </div>
          <div class="my-10">
            <!-- 1 -->
            <div class="card-columns" v-if="authorized">
              <div class="card rounded-0"  v-for="item in items" :key="item.id">
                <div class="artists_follow_card mb-7">
                  <div class="artists_follow_img_link">
                    <div
                      style="background-image:url(https://storage.gra1.cloud.ovh.net/v1/AUTH_10e1a9235c63431c95e5b84a247830db/prod/artwork/658452_1_grid.jpg)"
                      class="artists_follow_img"
                    ></div>
                  </div>
                  <div class="mt-1 mb-3">
                    <div class="bold text-uppercase m-0">Shintaro Miyake</div>
                    <div class="artist-cell-item__bio">Japanese, b. 1970</div>
                    <div class="artist-cell-item__followers">
                      59 works, 31 for sale
                    </div>
                  </div>
                  <button
                    class="btn btn-follow black btn-block text-uppercase py-0 _flex _center"
                  >
                    <i class="icon-follow mr-1"></i> Suivre
                  </button>
                </div>
              </div>
            </div>

            <div
              class="d-flex w-100 flex-column align-items-center mt-8 mb-20"
              v-if="items.length < 1 && isLoggedIn"
            >
              <i class="fas fa-exclamation-circle" style="font-size:120px"></i>
              <br />
              <h4 style="max-width:400px;text-align:center;font-weight:600">
                Vous n'avez pas des artistes favoris
              </h4>
            </div>

            <!--  -->
            <not-authenticated />
          </div>
        </div>
      </div>
      <!--  -->
    </section>
    <!--  -->

    <main-footer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters(["isLoggedIn", "favorite_artists"]),
    items() {
      return this.favorite_artists || [];
    },
    count() {
      return this.favorite_artists_count;
    },
    authorized() {
      if (this.isLoggedIn && this.items.length) return true;
      return false;
    },
  },
  methods: {},
  mounted() {
    this.loading = true;
    this.$store
      .dispatch("fetchFavoriteArtists")
      .then(() => {
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
      });
  },
};
</script>

<style></style>
